<template>
  <template v-if="effects && effects.length">
    <template v-for="(eff, idx) in effects" :key="idx">
      <effect-info :effect-details="eff" :idx="idx" />
    </template>
  </template>
  <template v-if="effects && effects.length == 0 && !loading">
    <no-content />
  </template>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { formatDateElapsedTime } from "@/utils/date.js";
import { capitalize } from "@/utils/string.js";
import EffectInfo from "@/components/effects/EffectInfo.vue";
import NoContent from "@/components/NoContent.vue";

export default defineComponent({
  name: "EffectsSummary",
  components: {
    EffectInfo,
    NoContent,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const effects = computed(() => {
      switch (route.name) {
        case "OpDetailsRoute":
          return store.getters["operations/getSelectedOp"].effects;
        case "AccountDetailsRoute":
          return store.getters["accounts/getAccount"].effects_r;
        case "LiquidityPoolDetailsRoute":
          return store.getters["liquidity_pools/getLiquidityPoolEffects"];
        case "LedgerDetailsRoute":
          return store.getters["ledgers/getLedgerEffects"];
        default:
          return store.getters["effects/getLatestEffects"];
      }
    });

    const loading = computed(() => {
      switch (route.name) {
        case "LiquidityPoolDetailsRoute":
          return store.getters["liquidity_pools/getLoadingTabStatus"];
        case "LedgerDetailsRoute":
          return store.getters["ledgers/getLoadingTabStatus"];
        default:
          return store.getters["effects/getLoadingStatus"];
      }
    });
    const error = computed(() => store.getters["effects/getError"]);

    return {
      store,
      formatDateElapsedTime,
      effects,
      loading,
      error,
      capitalize,
    };
  },
});
</script>
