<template>
  <template v-if="txns && txns.length">
  <div v-for="(txn, idx) in txns" :key="idx" class="list-group mb-2">
    <div class="list-group-item">
      <div class="d-flex align-items-center">
        <router-link
          v-tooltip
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('txn_summary.transaction')"
          :to="{
            name: 'TxnDetailsRoute',
            params: {
              txn_hash: txn.hash,
            },
          }"
        >
          <i
            class="bi bi-list-ul fs-5 align-self-center mx-auto"
          ></i>
        </router-link>
        <div class="flex-shrink-0 ms-3">
          <router-link
            v-tooltip
            v-middle-ellipsis="{ size: 6, text: txn.source_account }"
            :to="{
              name: 'AccountDetailsRoute',
              params: {
                acc_id: txn.source_account,
              },
            }"
            class="fw-bold text-decoration-none font-monospace small text-reset"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            :data-bs-original-title="$t('txn_summary.source_account')"
          />

          <p class="small mb-0 font-monospace">
            <router-link
              v-tooltip
              v-middle-ellipsis="{ size: 8, text: txn.hash }"
              :to="{
                name: 'TxnDetailsRoute',
                params: {
                  txn_hash: txn.hash,
                },
              }"
              class="text-muted text-decoration-none"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :data-bs-original-title="$t('txn_summary.txn_hash')"
            />
          </p>
        </div>
        <div class="flex-grow-1">
          <div class="row ms-2">
            <div class="col-xxl-4 col-4 d-flex">
              <span
                v-if="!accountLabels.data[txn.source_account]"
                class="badge bg-primary bg-opacity-25 align-self-center"
              >
                <router-link
                  v-tooltip
                  :to="{
                    name: 'DirectoryUpdateRoute',
                    query: {
                      account: txn.source_account,
                    },
                  }"
                  class="
                    text-primary text-decoration-none
                    d-xxl-inline-flex
                  "
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :data-bs-original-title="$t('txn_summary.add_label')"
                  ><i class="bi bi-plus-circle"></i
                ></router-link>
              </span>
              <span
                v-else-if="
                  ['Scam', 'Hack'].includes(
                    accountLabels.data[txn.source_account].label.name
                  )
                "
                class="badge bg-danger bg-opacity-25 align-self-center"
              >
                <router-link
                  v-tooltip
                  :to="{
                    name: 'AccountDetailsRoute',
                    params: {
                      acc_id: txn.source_account,
                    },
                  }"
                  class="
                    text-danger text-decoration-none
                    d-none d-xxl-inline-flex
                  "
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title=""
                  :data-bs-original-title="
                    $t('txn_summary.scam_tooltip', {
                      type: accountLabels.data[
                        txn.source_account
                      ].label.name.toUpperCase(),
                    })
                  "
                  >{{
                    accountLabels.data[
                      txn.source_account
                    ].label.name.toUpperCase()
                  }}</router-link
                >
              </span>
              <span
                v-else-if="
                  accountLabels.data[txn.source_account].label.verified
                "
                class="badge bg-primary bg-opacity-25 align-self-center"
              >
                <router-link
                  v-tooltip
                  :to="{
                    name: 'AccountDetailsRoute',
                    params: {
                      acc_id: txn.source_account,
                    },
                  }"
                  class="
                    text-primary text-decoration-none
                    d-none d-xxl-inline-flex
                  "
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title=""
                  :data-bs-original-title="$t('txn_summary.label')"
                  >{{
                    accountLabels.data[txn.source_account].label.name + "&nbsp;"
                  }}</router-link
                ><i
                  v-tooltip
                  class="bi bi-patch-check-fill text-primary"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title=""
                  :data-bs-original-title="$t('txn_summary.verified_account')"
                >
                </i>
              </span>

              <span
                v-else-if="
                  accountLabels.data[txn.source_account] &&
                  !accountLabels.data[txn.source_account].label.verified
                "
                class="badge border align-self-center"
              >
                <router-link
                  v-tooltip
                  :to="{
                    name: 'AccountDetailsRoute',
                    params: {
                      acc_id: txn.source_account,
                    },
                  }"
                  class="
                    text-decoration-none
                    d-none d-xxl-inline-flex
                  "
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title=""
                  :data-bs-original-title="$t('txn_summary.label')"
                  >{{
                    accountLabels.data[txn.source_account].label.name + "&nbsp;"
                  }}</router-link
                >
              </span>
            </div>
            <div class="col-xxl-2 d-none d-xxl-block">
              <span class="d-block small text-muted"
                ><span>{{ $t("txn_summary.ledger") }}</span></span
              >
              <router-link
                class="
                  fw-bold
                  font-monospace
                  small
                  text-reset
                  text-decoration-none 
                "
                :to="{
                  name: 'LedgerDetailsRoute',
                  params: {
                    ledger_sequence: txn.ledger_attr,
                  },
                }"
                >{{ txn.ledger_attr }}</router-link
              >
            </div>
            <div class="col-xxl-2 d-none d-xxl-block">
              <span class="d-block small text-muted"
                ><span>{{ $t("txn_summary.fee") }}</span></span
              >
              <small
                v-tooltip
                class="fw-bold font-monospace"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title=""
                :data-bs-original-title="
                  '$ ' + checkTxnValue(txn.hash, 'usd_fee')
                "
                >{{
                  $t("txn_summary.xlm") +
                  (0.0000001 * txn.fee_charged).toFixed(5)
                }}</small
              >
            </div>
            <div class="col-xxl-4 col-8 text-end">
              <b class="d-block small text-muted"
                >
                <span
                  v-tooltip
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title=""
                  :data-bs-original-title="formatDate(txn.created_at)"
                  >ended {{ formatDateElapsedTime(txn.created_at) }}</span
                ></b
              >
              <small
                v-tooltip
                class="fw-bold font-monospace"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title=""
                :data-bs-original-title="
                  '$ ' + checkTxnValue(txn.hash, 'usd_value')
                "
              >
                {{
                  $t("txn_summary.xlm") + checkTxnValue(txn.hash, "xlm_value")
                }}</small
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list-group-item d-flex">
      <span class="badge me-2
                  bg-opacity-25
                  align-self-center"
            :class="{ 'bg-danger text-danger': !txn.successful, 'bg-success text-success': txn.successful }"
        >{{ txn.operation_count }}
        {{ txn.operation_count === 1 ? " Operation" : " Operations" }}
      </span>
      <div class="progress w-100 d-flex" style="height: 22.38px">
        <div
          v-for="(op, idx) in txn.operations"
          :key="idx"
          v-tooltip
          class="text-center bg-body border rounded"
          role="progressbar"
          :style="`width: ${100 / txn.operation_count}%`"
          :aria-valuenow="100 / txn.operation_count"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title=""
          :data-bs-original-title="
            opTypes[op.body().switch().value].text
          "
        >
          <i
            class="bi text-primary"
            :class="opTypes[op.body().switch().value].iconClass"
          ></i>
        </div>
      </div>
    </div>
  </div>
  </template>
  <template v-else-if="!loading && txns && txns.length == 0">
    <no-content /> 
  </template>
</template>
<script>

import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { formatDateElapsedTime, formatDate } from "@/utils/date.js";
import  NoContent from "@/components/NoContent.vue";
export default defineComponent({
  name: "TxnSummary",
  components: {
    NoContent,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const route = useRoute();
    const opTypes = ref({
      0: {
        text: t("txn_summary.ops.create_account"),
        iconClass: "bi-plus-circle-fill",
      },
      1: {
        text: t("txn_summary.ops.payment"),
        iconClass: "bi-arrow-right-circle-fill",
      },
      2: {
        text: t("txn_summary.ops.path_payment_strict_receive"),
        iconClass: "bi-shuffle bi-flipped",
      },
      3: {
        text: t("txn_summary.ops.manage_sell_offer"),
        iconClass: "bi-arrow-up-circle-fill",
      },
      4: {
        text: t("txn_summary.ops.create_passive_sell_offer"),
        iconClass: "bi-arrow-up-square-fill ",
      },
      5: {
        text: t("txn_summary.ops.set_options"),
        iconClass: "bi-info-circle-fill",
      },
      6: {
        text: t("txn_summary.ops.change_trust"),
        iconClass: "bi-slash-circle-fill",
      },
      7: {
        text: t("txn_summary.ops.allow_trust"),
        iconClass: "bi-check-circle-fill",
      },
      8: { text: t("txn_summary.ops.account_merge"), iconClass: "bi-union" },
      9: {
        text: t("txn_summary.ops.inflation"),
        iconClass: "bi-cash-coin",
      },
      10: { text: t("txn_summary.ops.manage_data"), iconClass: "bi-server" },
      11: {
        text: t("txn_summary.ops.bump_sequence"),
        iconClass: "bi-sort-numeric-down-alt",
      },
      12: {
        text: t("txn_summary.ops.manage_buy_offer"),
        iconClass: "bi-arrow-down-circle-fill",
      },
      13: {
        text: t("txn_summary.ops.path_payment_strict_send"),
        iconClass: "bi-shuffle",
      },
      14: {
        text: t("txn_summary.ops.create_claimable_balance"),
        iconClass: "bi-layer-forward",
      },
      15: {
        text: t("txn_summary.ops.claim_claimable_balance"),
        iconClass: "bi-layer-backward",
      },
      16: {
        text: t("txn_summary.ops.begin_sponsoring_future_reserves"),
        iconClass: "bi-bookmark-check-fill",
      },
      17: {
        text: t("txn_summary.ops.end_sponsoring_future_reserves"),
        iconClass: "bi-bookmark-x-fill",
      },
      18: {
        text: t("txn_summary.ops.revoke_sponsorship"),
        iconClass: "bi-bookmark-dash-fill",
      },
      19: {
        text: t("txn_summary.ops.clawback"),
        iconClass: "bi-dash-square-fill",
      },
      20: {
        text: t("txn_summary.ops.clawback_claimable_balance"),
        iconClass: "bi-slash-square-fill ",
      },
      21: {
        text: t("txn_summary.ops.set_trustline_flags"),
        iconClass: "bi-ui-checks",
      },
      22: {
        text: t("txn_summary.ops.liquidity_pool_deposit"),
        iconClass: "bi-cloud-arrow-up-fill",
      },
      23: {
        text: t("txn_summary.ops.liquidity_pool_withdraw"),
        iconClass: "bi-cloud-arrow-down-fill",
      },
      24: {
        text: t("txn_summary.ops.invoke_host_function_op"),
        iconClass: "bi-clipboard2-pulse-fill",
      },
      25: {
        text: t("txn_summary.ops.bump_footprint_expiration"),
        iconClass: "bi-clock-fill",
      },
      26: {
        text: t("txn_summary.ops.restore_footprint"),
        iconClass: "bi-arrow-repeat",
      }
    });

    const loading = computed(() => {
      switch (route.name) {
        case "LedgerDetailsRoute":
          return store.getters["transaction/getLoadingStatus"];
        case "AccountDetailsRoute":
          return store.getters["transaction/getLoadingStatus"];
        case "LiquidityPoolDetailsRoute":
          return store.getters["liquidity_pools/getLoadingTabStatus"];
        default:
          return store.getters["transaction/getLoadingStatus"];
      }
    });

    const txns = computed(() => {
      switch (route.name) {
        case "LedgerDetailsRoute":
          return store.getters["ledgers/getSelectedLedger"].transactions;
        case "AccountDetailsRoute":
          return store.getters["accounts/getAccount"].transactions_r;
        case "LiquidityPoolDetailsRoute":
          return store.getters["liquidity_pools/getLiquidityPoolTransactions"];
        default:
          return store.getters["transactions/getLatestTransactions"];
      }
    });

    const txnValue = computed(() => store.getters["analytics/getTxnValue"]);
    const checkTxnValue = (txnHash, value) => {
      if (txnHash in txnValue.value.data) {
        return txnValue.value.data[txnHash][value].toLocaleString();
      } else if (!txnValue.value.loading && !txnValue.value.error) {
        return 0;
      } else {
        return "-";
      }
    };
    const accountLabels = computed(() => store.getters["accounts/getLabels"]);

    return {
      store,
      formatDateElapsedTime,
      formatDate,
      txns,
      opTypes,
      t,
      txnValue,
      checkTxnValue,
      accountLabels,
      loading
    };
  },
});
</script>
<style scoped>
.bi-flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}
</style>
