<template>
  <div class="card mb-2">
    <div class="card-body">
      <div class="text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">{{ $t("loading.placeholder") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Loading",
  setup() {},
});
</script>

<style>
</style>