<template>
  <div class="row g-2 mb-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header bg-body-tertiary d-flex justify-content-between">
          <span>{{ $t("liquidity_pools_summary.details.pool_info") }}</span>
        </div>
        <div class="card-body">
          <div class="card">
            <div class="card-body d-flex">
              <div class="w-25 py-2 px-3 bg-body-tertiary">
                <i class="bi bi-coin me-3"></i>
                {{ $t("liquidity_pools_summary.details.reserve_asset") }}
              </div>
              <div class="w-25 py-2 px-3 text-end">
                <div class="small font-monospace text-decoration-none">
                    -
                </div>
              </div>
              <div class="w-25 py-2 px-3">
                <div class="small font-monospace text-decoration-none">
                    -
                </div>
              </div>
              <div class="w-25 py-2 px-3 bg-body-tertiary">
                <i class="bi bi-coin me-3"></i>
                {{ $t("liquidity_pools_summary.details.reserve_asset") }}
              </div>
            </div>
          </div>
          <ul class="list-group">
            <li class="list-group-item d-flex p-0 border-0 mt-1">
              <div class="w-25 py-2 px-3 bg-body-tertiary">
                <i class="bi bi-tag-fill me-3"></i>
                {{ $t("liquidity_pools_summary.details.type") }}
              </div>
              <div class="w-25 py-2 px-3">
                <div class="text-decoration-none font-monospace small">
                    -
                </div>
              </div>
              <div class="w-25 py-2 px-3 bg-body-tertiary">
                <i class="bi bi-arrow-right-circle-fill me-3"></i>
                {{ $t("liquidity_pools_summary.details.total_shares") }}
              </div>
              <div class="w-25 py-2 px-3">
                <div class="small font-monospace text-decoration-none">
                    -
                </div>
              </div>
            </li>
            <li class="list-group-item d-flex p-0 border-0 mt-1">
              <div class="w-25 py-2 px-3 bg-body-tertiary">
                <i class="bi bi-arrow-right-circle-fill me-3"></i>
                {{ $t("liquidity_pools_summary.details.total_accounts") }}
              </div>
              <div class="w-25 py-2 px-3">
                <div class="small font-monospace text-decoration-none">
                    -
                </div>
              </div>
              <div class="w-25 py-2 px-3 bg-body-tertiary">
                <i class="bi bi-arrow-right-circle-fill me-3"></i
                >{{ $t("liquidity_pools_summary.details.fee_bp") }}
              </div>
              <div class="w-25 py-2 px-3">
                <div class="small font-monospace text-decoration-none">
                    -
                </div>
              </div>
            </li>
            <li class="list-group-item d-flex p-0 border-0">
              <div class="w-25 py-2 px-3 bg-body-tertiary">
                <i class="bi bi-arrow-left-right me-3"></i>
                {{ $t("liquidity_pools_summary.details.ledger_id") }}
              </div>
              <div class="w-25 py-2 px-3">
                <div class="small font-monospace text-decoration-none">
                    -
                </div>
              </div>
              <div class="w-25 py-2 px-3 bg-body-tertiary">
                <i class="bi bi-arrow-right-circle-fill me-3"></i>
                {{ $t("liquidity_pools_summary.details.updated_at") }}
              </div>
              <div class="w-25 py-2 px-3">
                <div class="small font-monospace text-decoration-none">
                    -
                </div>
              </div>
            </li>
            <li class="list-group-item d-flex p-0 border-0">
              <div class="w-25 py-2 px-3 bg-body-tertiary">
                <i class="bi bi-arrow-left-right me-3"></i>
                {{ $t("liquidity_pools_summary.details.trades") }}
              </div>
              <div class="w-25 py-2 px-3">
                <div class="small font-monospace text-decoration-none">0</div>
              </div>
              <div class="w-25 py-2 px-3 bg-body-tertiary">
                <i class="bi bi-arrow-right-circle-fill me-3"></i>
                {{ $t("liquidity_pools_summary.details.trades_volume") }}
              </div>
              <div class="w-25 py-2 px-3">
                <div class="small font-monospace text-decoration-none">0</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "LiquidityPoolDetailsStatsSkeleton",
  components: {},
  setup() {
    return {};
  },
});
</script>
