<template>
  <liquidity-pool-details-stats-skeleton v-if="loading" />
  <liquidity-pool-details-stats-skeleton v-else-if="error" />
  <template v-else>
    <div class="row g-2 mb-2">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header bg-body-tertiary d-flex justify-content-between">
            <span>{{ $t("liquidity_pools_summary.details.pool_info") }}</span>
          </div>
          <div class="card-body">
            <div class="card">
              <div class="card-body d-flex">
                <div class="w-25 py-2 px-3 ">
                  {{ $t("liquidity_pools_summary.details.reserve_asset") }}
                  <i class="bi bi-coin me-3"></i>
                </div>
                <div class="w-25 py-2 px-3">
                  <div class="small text-center font-monospace text-decoration-none">
                    <div>
                        <img
                            :src="
                            liquidityPoolDetails.reserves[0].asset === 'native'
                            ? xlmLogo
                            : assetMeta(liquidityPoolDetails.reserves[0].asset)?.image
                        "
                        class="mx-auto rounded-circle img-fluid w-25"
                      />
                    </div>
                    <asset-details-link
                      v-tooltip
                      style="height: 36px; width: 36px"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      class="text-decoration-none text-body-tertiary fw-bold"
                      :data-bs-original-title="
                        $t('liquidity_pools_summary.asset_icon')
                      "
                      :sep11="liquidityPoolDetails.reserves[0].asset"
                    >
                      {{ liquidityPoolDetails.reserves[0].asset.split(":")[0] !== "native" ? 
                        liquidityPoolDetails.reserves[0].asset.split(":")[0] : 'XLM' }}
                    </asset-details-link>
                    <div>
                        <router-link
                          v-if="liquidityPoolDetails.reserves[0].asset !== 'native'"
                          v-tooltip
                          v-middle-ellipsis="{
                            size: 10,
                            text: liquidityPoolDetails.reserves[0].asset.split(':'),
                          }"
                          :to="{
                            name: 'AccountDetailsRoute',
                            params: {
                              acc_id: liquidityPoolDetails.reserves[0].asset.split(':'),
                            },
                          }"
                          class="text-muted text-decoration-none"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          :data-bs-original-title="$t('order.source_account')"
                        >
                        </router-link>
                    </div>
                  </div>
                </div>
                <div class="w-25 py-2 px-3 text-end">
                  <div class="small font-monospace text-center text-decoration-none">
                    <div>
                        <img
                            :src="
                              liquidityPoolDetails.reserves[1].asset === 'native'
                                ? xlmLogo
                                : assetMeta(liquidityPoolDetails.reserves[1].asset)
                                ?.image
                        "
                        class="mx-auto rounded-circle img-fluid w-25"
                     />                 
                    </div>
                    <asset-details-link
                      v-tooltip
                      style="height: 36px; width: 36px"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      class="text-decoration-none fw-bold text-body"
                      :data-bs-original-title="
                        $t('liquidity_pools_summary.asset_icon')
                      "
                      :sep11="liquidityPoolDetails.reserves[1].asset"
                    >{{ liquidityPoolDetails.reserves[1].asset.split(":")[0] !== "native" ? 
                    liquidityPoolDetails.reserves[1].asset.split(":")[0] : 'XLM' }}                    </asset-details-link>
                    <div>
                      <router-link
                        v-if="liquidityPoolDetails.reserves[1].asset !== 'native'"
                        v-tooltip
                        v-middle-ellipsis="{
                          size: 10,
                          text: liquidityPoolDetails.reserves[1].asset.split(':')[1],
                        }"
                        :to="{
                          name: 'AccountDetailsRoute',
                          params: {
                            acc_id: liquidityPoolDetails.reserves[1].asset.split(':')[1],
                          },
                        }"
                        class="text-muted text-decoration-none"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :data-bs-original-title="$t('order.source_account')"
                      >
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="w-25 py-2 px-3  text-end">
                  <i class="bi bi-coin"></i>
                  {{ $t("liquidity_pools_summary.details.reserve_asset") }}
                </div>
              </div>
            </div>
            <ul class="list-group">
              <li class="list-group-item d-flex p-0 border-0 mt-1">
                <div class="w-25 py-2 px-3 ">
                  <i class="bi bi-tag-fill me-3"></i>
                  {{ $t("liquidity_pools_summary.details.type") }}
                </div>
                <div class="w-25 py-2 px-3">
                  <div class="text-decoration-none font-monospace small">
                    {{ splitAndCapitalize(liquidityPoolDetails.type).replace("_", " ") }}
                  </div>
                </div>
                <div class="w-25 py-2 px-3 ">
                  <i class="bi bi-arrow-right-circle-fill me-3"></i>
                  {{ $t("liquidity_pools_summary.details.total_shares") }}
                </div>
                <div class="w-25 py-2 px-3">
                  <div class="small font-monospace text-decoration-none">
                    {{ liquidityPoolDetails.total_shares }}
                  </div>
                </div>
              </li>
              <li class="list-group-item d-flex p-0 border-0 mt-1">
                <div class="w-25 py-2 px-3 ">
                  <i class="bi bi-arrow-right-circle-fill me-3"></i>
                  {{ $t("liquidity_pools_summary.details.total_accounts") }}
                </div>
                <div class="w-25 py-2 px-3">
                  <div class="small font-monospace text-decoration-none">
                    {{ liquidityPoolDetails.total_trustlines }}
                  </div>
                </div>
                <div class="w-25 py-2 px-3 ">
                  <i class="bi bi-arrow-right-circle-fill me-3"></i>
                  {{ $t("liquidity_pools_summary.details.fee_bp") }}
                </div>
                <div class="w-25 py-2 px-3">
                  <div class="small font-monospace text-decoration-none">
                    {{ liquidityPoolDetails.fee_bp }}
                  </div>
                </div>
              </li>
              <li class="list-group-item d-flex p-0 border-0">
                <div class="w-25 py-2 px-3 ">
                  <i class="bi bi-arrow-left-right me-3"></i>
                  {{ $t("liquidity_pools_summary.details.ledger_id") }}
                </div>
                <div class="w-25 py-2 px-3">
                  <div class="small font-monospace text-decoration-none">
                    {{ liquidityPoolDetails.last_modified_ledger }}
                  </div>
                </div>
                <div class="w-25 py-2 px-3 ">
                  <i class="bi bi-arrow-right-circle-fill me-3"></i>
                  {{ $t("liquidity_pools_summary.details.updated_at") }}
                </div>
                <div class="w-25 py-2 px-3">
                  <div class="small font-monospace text-decoration-none">
                    {{ liquidityPoolDetails.last_modified_time }}
                  </div>
                </div>
              </li>
              <li class="list-group-item d-flex p-0 border-0">
                <div class="w-25 py-2 px-3 ">
                  <i class="bi bi-arrow-left-right me-3"></i>
                  {{ $t("liquidity_pools_summary.details.trades") }}
                </div>
                <div class="w-25 py-2 px-3">
                  <div class="small font-monospace text-decoration-none">-</div>
                </div>
                <div class="w-25 py-2 px-3 ">
                  <i class="bi bi-arrow-right-circle-fill me-3"></i>
                  {{ $t("liquidity_pools_summary.details.trades_volume") }}
                </div>
                <div class="w-25 py-2 px-3">
                  <div class="small font-monospace text-decoration-none">-</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { splitAndCapitalize } from "@/utils/string.js";
import { useI18n } from "vue-i18n";
import LiquidityPoolDetailsStatsSkeleton from "../skeletons/stats/LiquidityPoolDetailsStatsSkeleton.vue";
import AssetDetailsLink from "@/components/links/AssetDetailsLink.vue";

export default defineComponent({
  name: "LiquidityPoolDetailsStats",
  components: {
    AssetDetailsLink,
    LiquidityPoolDetailsStatsSkeleton,
  },
  props: {
    isNative: Boolean,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const { t } = useI18n();
    const assetStatsChart = ref();

    const poolId = ref(route.params.pool_id);
    store.dispatch("liquidity_pools/fetchLiquidityPool", poolId.value);

    const liquidityPoolDetails = computed(
      () => store.getters["liquidity_pools/getLiquidityPool"]
    );

    const loading = computed(
      () => store.getters["liquidity_pools/getLoadingStatus"]
    );
    const error = computed(
      () => store.getters["assets/getSelectedAsset"].error
    );

    const copy = (value) => {
      navigator.clipboard.writeText(value);
    };

    const assetsMeta = computed(
      () => store.getters["assets/getAssetsMeta"].data
    );

    const assetMeta = (asset) => {
      const arr = asset.split(":");
      return assetsMeta.value[arr[0] + "_" + arr[1]];
    };

    return {
      xlmLogo: require("@/assets/images/placeholder.jpeg"),
      liquidityPoolDetails,
      loading,
      error,
      splitAndCapitalize,
      copy,
      assetStatsChart,
      assetMeta,
    };
  },
});
</script>
