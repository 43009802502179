<template>
<template v-if="trades && trades.length">
  <template v-for="(trade, idx) in trades" :key="idx">
    <div class="card mb-2">
      <div class="card-body py-2">
        <div class="d-flex">
          <div
            class="
              flex-shrink-0
              align-self-center
              d-none d-xxl-inline-flex
              me-1
            "
          >
            <router-link
              class="flex-shrink-0 bg-primary bg-opacity-25 rounded-3 d-flex"
              style="height: 36px; width: 36px"
              data-bs-html="true"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :data-bs-original-title="`${$t('trades_summary.trade')} ${idx}`"
              v-tooltip
              :to="{
                name: 'OpDetailsRoute',
                params: {
                  op_id: trade.id,
                },
              }"
            >
              <i
                class="
                  bi bi-arrow-left-right
                  fs-5
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </router-link>
          </div>
          <div class="flex-grow-1">
            <div class="row g-2">
              <div class="col-xxl-3 d-flex align-items-center">
                <template v-if="'base_account' in trade">
                  <div class="flex-grow-1 ms-2 text-truncate">
                    <router-link
                      :to="{
                        name: 'AccountDetailsRoute',
                        params: {
                          acc_id: trade.base_account,
                        },
                      }"
                      class="
                        fw-bold
                        text-decoration-none text-dark
                        font-monospace
                        small
                      "
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="$t('trades_summary.label')"
                      v-tooltip
                    >
                      {{
                        accountLabels.data[trade.base_account]
                          ? accountLabels.data[trade.base_account].label.name
                          : $t("trades_summary.unknown")
                      }}
                    </router-link>
                    <p
                      v-if="'base_account' in trade"
                      class="small mb-0 font-monospace"
                    >
                      <router-link
                        :to="{
                          name: 'AccountDetailsRoute',
                          params: {
                            acc_id: trade.base_account,
                          },
                        }"
                        class="text-muted text-decoration-none"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :data-bs-original-title="
                          $t('trades_summary.base_account')
                        "
                        v-tooltip
                        v-middle-ellipsis="{
                          size: 6,
                          text: trade.base_account,
                        }"
                      >
                      </router-link>
                    </p>
                  </div>
                  <div class="flex-shrink-0 me-2">
                    <account-label :account="trade.base_account" />
                  </div>
                </template>
              </div>
              <div
                class="col-xxl-3 align-items-center d-flex bg-light rounded"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                v-tooltip
                :data-bs-original-title="$t('trades_summary.base_asset')"
              >
                <asset-details-link
                  class="flex-shrink-0 d-flex mx-1"
                  style="height: 36px; width: 36px"
                  :type="trade.base_asset_type"
                  :code="trade.base_asset_code"
                  :issuer="trade.base_asset_issuer"
                >
                  <img
                    v-if="trade.base_asset_type === 'native'"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.asset_icon')"
                    v-tooltip
                    :src="xlmLogo"
                    class="px-1 align-self-center mx-auto img-fluid"
                  />
                  <img
                    v-else-if="
                      assetMeta({
                        asset_code: trade.base_asset_code,
                        asset_issuer: trade.base_asset_issuer,
                      })?.image
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.asset_icon')"
                    v-tooltip
                    :src="
                      assetMeta({
                        asset_code: trade.base_asset_code,
                        asset_issuer: trade.base_asset_issuer,
                      })?.image
                    "
                    class="px-1 align-self-center mx-auto img-fluid"
                  />
                  <i
                    v-else
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.asset_icon_not_available')
                    "
                    v-tooltip
                    class="
                      bi bi-circle-fill
                      fs-5
                      align-self-center
                      mx-auto
                      text-muted
                    "
                  ></i>
                </asset-details-link>
                <div class="flex-grow-1 ms-1">
                  <div
                    class="
                      fw-bold
                      text-dark
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      :type="trade.base_asset_type"
                      :code="trade.base_asset_code"
                      :issuer="trade.base_asset_issuer"
                      class="text-decoration-none text-dark"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      v-tooltip
                      :data-bs-original-title="$t('trades_summary.asset_name')"
                    >
                      {{
                        trade.base_asset_type === "native"
                          ? $t("trades_summary.xlm")
                          : trade.base_asset_code
                      }}</asset-details-link
                    >
                    <span> {{ trade.base_amount }}</span>
                  </div>
                  <div
                    class="
                      text-dark
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      v-if="trade.base_asset_type === 'native'"
                      :type="trade.base_asset_type"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('trades_summary.native_asset_of_stellar_network')
                      "
                      v-tooltip
                    >
                      {{ $t("trades_summary.native") }}
                    </asset-details-link>
                    <asset-details-link
                      v-else
                      :type="trade.base_asset_type"
                      :code="trade.base_asset_code"
                      :issuer="trade.base_asset_issuer"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('trades_summary.asset_issuer')
                      "
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: trade.base_asset_issuer,
                      }"
                    ></asset-details-link>
                  </div>
                </div>
              </div>
              <div class="col-xxl-3 d-flex align-items-center">
                <template v-if="'counter_account' in trade">
                  <div class="flex-grow-1 ms-2 text-truncate">
                    <router-link
                      :to="{
                        name: 'AccountDetailsRoute',
                        params: {
                          acc_id: trade.counter_account,
                        },
                      }"
                      class="
                        fw-bold
                        text-decoration-none text-dark
                        font-monospace
                        small
                      "
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="$t('trades_summary.label')"
                      v-tooltip
                    >
                      {{
                        accountLabels.data[trade.counter_account]
                          ? accountLabels.data[trade.counter_account].label.name
                          : $t("trades_summary.unknown")
                      }}
                    </router-link>
                    <p class="small mb-0 font-monospace">
                      <router-link
                        :to="{
                          name: 'AccountDetailsRoute',
                          params: {
                            acc_id: trade.counter_account,
                          },
                        }"
                        class="text-muted text-decoration-none"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :data-bs-original-title="
                          $t('trades_summary.counter_account')
                        "
                        v-tooltip
                        v-middle-ellipsis="{
                          size: 6,
                          text: trade.counter_account,
                        }"
                      >
                      </router-link>
                    </p>
                  </div>
                  <div class="flex-shrink-0 me-2">
                    <account-label :account="trade.counter_account" />
                  </div>
                </template>
              </div>
              <div
                class="col-xxl-3 align-items-center d-flex bg-light rounded"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                v-tooltip
                :data-bs-original-title="$t('trades_summary.counter_asset')"
              >
                <asset-details-link
                  class="flex-shrink-0 d-flex mx-1"
                  style="height: 36px; width: 36px"
                  :type="trade.counter_asset_type"
                  :code="trade.counter_asset_code"
                  :issuer="trade.counter_asset_issuer"
                >
                  <img
                    v-if="trade.counter_asset_type === 'native'"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.asset_icon')"
                    v-tooltip
                    :src="xlmLogo"
                    class="px-1 align-self-center mx-auto img-fluid"
                  />
                  <img
                    v-else-if="
                      assetMeta({
                        asset_code: trade.counter_asset_code,
                        asset_issuer: trade.counter_asset_issuer,
                      })?.image
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.asset_icon')"
                    v-tooltip
                    :src="
                      assetMeta({
                        asset_code: trade.counter_asset_code,
                        asset_issuer: trade.counter_asset_issuer,
                      })?.image
                    "
                    class="px-1 align-self-center mx-auto img-fluid"
                  />
                  <i
                    v-else
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.asset_icon_not_available')
                    "
                    v-tooltip
                    class="
                      bi bi-circle-fill
                      fs-5
                      align-self-center
                      mx-auto
                      text-muted
                    "
                  ></i>
                </asset-details-link>
                <div class="flex-grow-1 ms-1">
                  <div
                    class="
                      fw-bold
                      text-dark
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      :type="trade.counter_asset_type"
                      :code="trade.counter_asset_code"
                      :issuer="trade.counter_asset_issuer"
                      class="text-decoration-none text-dark"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      v-tooltip
                      :data-bs-original-title="$t('trades_summary.asset_name')"
                    >
                      {{
                        trade.counter_asset_type === "native"
                          ? $t("trades_summary.xlm")
                          : trade.counter_asset_code
                      }}</asset-details-link
                    >
                    <span> {{ trade.counter_amount }}</span>
                  </div>
                  <div
                    class="
                      text-dark
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      v-if="trade.counter_asset_type === 'native'"
                      :type="trade.counter_asset_type"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('trades_summary.native_asset_of_stellar_network')
                      "
                      v-tooltip
                    >
                      {{ $t("trades_summary.native") }}
                    </asset-details-link>
                    <asset-details-link
                      v-else
                      :type="trade.counter_asset_type"
                      :code="trade.counter_asset_code"
                      :issuer="trade.counter_asset_issuer"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('trades_summary.asset_issuer')
                      "
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: trade.counter_asset_issuer,
                      }"
                    ></asset-details-link>
                    <span
                      v-if="'ledger_close_time' in trade"
                      class="small"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      v-tooltip
                      title=""
                      :data-bs-original-title="
                        formatDate(trade.ledger_close_time)
                      "
                      >{{
                        formatDateElapsedTime(trade.ledger_close_time)
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
<template v-if="!loading && trades && trades.length == 0">
  <no-content />
</template>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import AssetDetailsLink from "@/components/links/AssetDetailsLink.vue";
import NoContent from "@/components/NoContent.vue";
import { useRoute } from "vue-router";
import { formatDate, formatDateElapsedTime } from "@/utils/date.js";
import AccountLabel from "@/components/AccountLabel.vue";
export default defineComponent({
  name: "TradesSummary",
  components: {
    AssetDetailsLink,
    AccountLabel,
    NoContent
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const trades = computed(() => {
      switch (route.name) {
        case "AccountDetailsRoute":
          return store.getters["accounts/getAccount"].trades_r;
        case "LiquidityPoolDetailsRoute":
          return store.getters["liquidity_pools/getLiquidityPoolTrades"];
        case "LedgerDetailsRoute":
          return store.getters["ledgers/getLedgerPayments"];
        default:
          return store.getters["trades/getLatestTrades"];
      }
    });

    const accountLabels = computed(() => store.getters["accounts/getLabels"]);
    const xlmLogo = require("@/assets/images/assets/xlm.png");
    const assetMeta = (asset) =>
      assetsMeta.value[asset.asset_code + "_" + asset.asset_issuer];

    const assetsMeta = computed(
      () => store.getters["assets/getAssetsMeta"].data
    );

    const loading = computed(() => {
      switch (route.name) {
        case "LiquidityPoolDetailsRoute":
          return store.getters["liquidity_pools/getLoadingTabStatus"];
        default:
          return store.getters["trades/getLoadingStatus"];
      }
    });
    return {
      store,
      formatDateElapsedTime,
      formatDate,
      trades,
      assetMeta,
      xlmLogo,
      loading,
      accountLabels,
    };
  },
});
</script>
