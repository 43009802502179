<template>
  <div class="d-flex mb-2">
    <div class="flex-fill">
      <div class="input-group">
        <search />
      </div>
    </div>
  </div>
  <liquidity-pool-details-stats />
  <div class="btn-group w-100" role="group">
    <div
      v-for="(tab, idx) in navTabs"
      :key="idx"
      :class="`btn btn-link border text-decoration-none w-100 bg-body
      ${currTab === tab.name ? 'text-primary' : 'text-body'}`"
      @click="onTabChange(tab)"
    >
        <i :class="`bi ${tab.icon}`"></i>
        <span class="d-none d-xl-inline d-xxl-inline ms-1">
            {{ $t(`account_details.tabs.${tab.name}`) }}
        </span>
    </div>
  </div>
  <div class="mt-2">
    <component
        :is="`${currTab}-tab`"
        v-if="currTab === 'assets'"
    />
    <component 
        :is="`${currTab}-tab`" 
        v-else 
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import { formatDate } from "@/utils/date";
import TransactionsTab from "@/components/liquidity_pool/TransactionsTab.vue";
import OperationsTab from "@/components/liquidity_pool/OperationsTab.vue";
import EffectsTab from "@/components/liquidity_pool/EffectsTab.vue";
import TradesTab from "@/components/liquidity_pool/TradesTab.vue";
import Search from "@/components/Search.vue";
import LiquidityPoolDetailsStats from "@/components/stats/LiquidityPoolDetailsStats.vue";

export default defineComponent({
  name: "LiquidityPoolDetails",
  components: {
    Search,
    LiquidityPoolDetailsStats,
    TransactionsTab,
    OperationsTab,
    EffectsTab,
    TradesTab,
  },
  setup() {
    const store = useStore();
    const currTab = ref("transactions");

    const server = computed(() => store.getters["settings/getApiServer"]);
    const muxedAccount = ref(null);

    const onTabChange = (tab) => {
      store.dispatch("accounts/resetEmptySlate");
      currTab.value = tab.name;
    };

    const navTabs = [
      { name: "transactions", icon: "bi-list-ul" },
      { name: "operations", icon: "bi-view-list" },
      { name: "effects", icon: "bi-clipboard-check" },
      { name: "trades", icon: "bi-arrow-left-right" },
    ];

    return {
      formatDate,
      navTabs,
      onTabChange,
      currTab,
      server,
      muxedAccount,
    };
  },
});
</script>
